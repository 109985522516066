.App {
  text-align: center;
}

.cave-stats {
  display: flex;
  justify-content: center;
}

.stats {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 1em 0 2vh;
}

.vita {
  padding: 0 1em;
}


.mana {
  padding: 0 1em;
}


.output {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .output {
    flex-direction: column;
  }
}

.sc-box {
  padding: 0 1vw;
  display: flex;
}

td,
th {
  padding: 0 1vw 1vh;
}

.cave-buttons {
  padding: 0 3vw;
}

.checkboxes {
  display: flex;
  padding: 0 3vw;
}

.target {
  display: flex;
  justify-content: center;
  padding: 0 2vw 3vh;
}

.class {
  margin: 0 auto;
  text-align: left;
}

body {
  background-color: #333;
  color: aliceblue;
}

button {
  color: black;
}

input {
  background-color: #333;
  color: aliceblue;
}

.title {
  color: aliceblue;
}

.checkbox-label {
  color: aliceblue;
  margin-left: 5px;
}

.vita-input {
  background-color: #333;
  border: 2px solid #ff0000;
  color: aliceblue;
  font-size: 2.5vw;
  margin: 10px 0;
}

.mana-input {
  background-color: #333;
  border: 2px solid #00ccff;
  color: aliceblue;
  font-size: 2.5vw;
  margin: 10px 0;
}

.first-spell {
  color: #ffe380;
}

.second-spell {
  color: #ff8f73;
}
.third-spell {
  color: #79e2f2;
}
.forth-spell {
  color: #d6a9d5;
}
.fifth-spell {
  color: #57d9a3;
}

.sixth-spell {
  color: #c39b77;
}